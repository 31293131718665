<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('transaction.status')"
    class="px-5 py-3"
    v-if="detail"
  >
    <v-row>
      <v-col cols="6">
        {{ $t('transaction.number') }}
      </v-col>
      <v-col cols="6">
        {{ detail.transaction_number }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('transaction.status') }}
      </v-col>
      <v-col cols="6">
        {{ detail.transaction_status }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('transaction.createdAt') }}
      </v-col>
      <v-col cols="6">
        {{ detail.created_at ? format(new Date(detail.created_at), 'dd MMMM yyyy HH:mm') : '' }}
      </v-col>
    </v-row>
    <v-row
      class="justify-center mt-6 mb-4"
      v-if="permissions.includes('EDIT') && detail.allow_update_transaction_status"
    >
      <v-btn type="button" color="success" @click="showFinish = true">
        {{ $t('transaction.btn.finish') }}
      </v-btn>
    </v-row>
    <v-row
      class="justify-center mt-6 mb-4"
      v-if="permissions.includes('EDIT') && detail.allow_update_transaction_status"
    >
      <v-btn type="button" color="success" @click="showCancel = true">
        {{ $t('transaction.btn.cancel') }}
      </v-btn>
    </v-row>
    <v-dialog v-model="showFinish" max-width="340">
      <v-card>
        <v-card-title>
          {{ $t('finishTransaction.title') }}
          <v-spacer />
          <v-icon aria-label="Close" @click="showFinish = false"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="pb-6 pt-12 text-center">
          <v-btn color="error" text @click="showFinish = false">
            {{ $t('general.no') }}
          </v-btn>
          <v-btn color="primary" text @click="finish">
            {{ $t('general.yes') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCancel" max-width="340">
      <v-card>
        <v-card-title>
          {{ $t('cancelTransaction.title') }}
          <v-spacer />
          <v-icon aria-label="Close" @click="showCancel = false"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="pb-6 pt-12 text-center">
          <v-btn color="error" text @click="showCancel = false">
            {{ $t('general.no') }}
          </v-btn>
          <v-btn color="primary" text @click="cancel">
            {{ $t('general.yes') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions', 'allowedActions'],
  data() {
    return {
      format,
      showFinish: false,
      showCancel: false,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.lead.basePath,
    }),
  },
  methods: {
    async finish() {
      try {
        await this.$store.dispatch('transaction/form/finish');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('transaction.finish.successMsg'),
          'success',
        );
        this.showFinish = false;
        await this.$store.dispatch('transaction/getDetailInitData', this.$route.params.uuid);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    async cancel() {
      try {
        await this.$store.dispatch('transaction/form/cancel');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('transaction.cancel.successMsg'),
          'success',
        );
        this.showCancel = false;
        await this.$store.dispatch('transaction/getDetailInitData', this.$route.params.uuid);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
